<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("loanRecovery.customerNameOrLoanNumber")
                    }}</label>
                    <Select
                        v-model="model.loan_id"
                        filterable
                        :remote-method="loadLoanDisbursement"
                        :loading="loanLoading"
                        clearable
                        :placeholder="
                            $t('loanRecovery.searchCustomerNameOrLoanNumber')
                        "
                        :class="{
                            'ivu-form-item-error is-invalid': errors.has('loan_id')
                        }"
                        :disabled="isUpdate"
                    >
                        <Option
                            v-for="(option, index) in loanDisbursements"
                            :value="option.loan_id"
                            :key="index"
                            :label="option.loan_number"
                        >
                            {{ option.loan_number }} |
                            {{ option.customer.customer_code }} |
                            {{ option.customer.customer_name_en }} |
                            {{ option.customer.customer_name_kh }} |
                            {{ option.customer.contact_no }}
                        </Option>
                    </Select>
                    <div class="invalid-feedback" v-if="errors.has('loan_id')">
                        {{ errors.first("loan_id") }}
                    </div>
                </div>
            </div>
            <div
                class="list-group-item list-group-item-action d-flex mb-3"
                v-if="loan"
            >
                <div class="flex-fill text-gray-600">
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanRecovery.customerCode") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.commune_code
                                        : ""
                                }}
                            </span>
                        </div>
                        <div class="tw-font-bold">
                            {{
                                loan.loan_type
                                    ? loan.loan_type.loan_type_en
                                    : ""
                            }}
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanRecovery.customerName") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.customer_name_en
                                        : ""
                                }}
                            </span>
                        </div>
                        <div class="text-gray-600">
                            {{ $t("loanRecovery.loanAmount") }}:
                            <span class="tw-font-bold">
                                {{
                                    formatCurrencyWithCode(
                                        loan.loan_amount,
                                        loan.currency
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanRecovery.contactNo") }}:
                            <span class="tw-font-bold">
                                {{
                                    loan.customer
                                        ? loan.customer.contact_no
                                        : ""
                                }}
                            </span>
                        </div>
                        <div>
                            {{ $t("loanRecovery.outstandingAmount") }}:
                            <span class="tw-font-bold text-warning">
                                {{
                                    formatCurrencyWithCode(
                                        loan.outstanding_amount,
                                        loan.currency
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between">
                        <div>
                            {{ $t("loanRecovery.address") }}:
                            <small>
                                {{
                                    loan.customer
                                        ? loan.customer.village
                                            ? loan.customer.village.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.commune
                                            ? loan.customer.commune.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.district
                                            ? loan.customer.district.name_en +
                                              ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.province
                                            ? loan.customer.province.name_en +
                                              "."
                                            : "-"
                                        : "-"
                                }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanRecovery.amount")
                    }}</label>
                    <Poptip trigger="focus" style="width: 100%">
                        <Input
                            :placeholder="$t('loanRecovery.enterNumber')"
                            v-model="model.amount"
                            :class="{
                                'ivu-form-item-error is-invalid': errors.has('amount')
                            }"
                        >
                        </Input>
                        <div slot="content">
                            {{
                                model.amount
                                    ? formatNumber(
                                          model.amount,
                                          loan ? loan.currency_id : 2
                                      )
                                    : $t("loanRecovery.enterNumber")
                            }}
                        </div>
                    </Poptip>
                    <div class="text-danger tw-text-xs mt-1" v-if="errors.has('amount')">
                        {{ errors.first("amount") }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("loanRecovery.recoveryDate")
                    }}</label>
                    <DatePicker
                        :value="model.recovery_date"
                        placeholder="DD-MM-YYYY"
                        format="dd-MM-yyyy"
                        @on-change="onChangeRecoveryDate"
                        :options="options"
                        style="width: 100%"
                        :disabled="isUpdate"
                    ></DatePicker>
                    <div class="text-danger" v-if="errors.has('recovery_date')">
                        {{ errors.first("recovery_date") }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t("loanRecovery.reason")
                    }}</label>
                    <textarea
                        v-model="model.remarks"
                        class="form-control"
                        rows="3"
                        :class="{
                            'is-invalid': errors.has('remarks')
                        }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first("remarks") }}
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                        :disabled="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { isEmpty, debounce } from "lodash";
import moment from "moment";

export default {
    name: "loanRecoveryForm",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            loanLoading: false,
            model: {
                loan_id: null,
                amount: null,
                recovery_date: moment().format("DD-MM-YYYY"),
                remarks: null
            },
            options: {
                disabledDate(date) {
                    return date.valueOf() > Date.now();
                }
            }
        };
    },
    computed: {
        ...mapState("creditOperation/loanRecovery", [
            "edit_data",
            "loanDisbursements"
        ]),
        ...mapGetters(["formatCurrencyWithCode", "formatNumber"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        loan() {
            if (this.loanDisbursements.length > 0 && this.model.loan_id) {
                return this.loanDisbursements.find(
                    l => l.loan_id == this.model.loan_id
                );
            }
            return null;
        }
    },
    methods: {
        ...mapActions("creditOperation/loanRecovery", ["getLoanDisbursement"]),
        onChangeRecoveryDate(date) {
            this.model.recovery_date = date;
        },
        loadLoanDisbursement: debounce(function(query) {
            this.errors = new Errors();
            this.loanLoading = true;
            this.getLoanDisbursement({ search: query }).finally(() => {
                this.loanLoading = false;
            });
        }, 1000),
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/loanRecovery/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if(response.message_id == 0) {
                        this.clearInput();
                        this.$emit("fetchData");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanRecovery/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if(response.message_id == 0) {
                        this.$emit("cancel");
                        this.$emit("fetchData");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanRecovery/update", {
                    id: this.edit_data.transaction_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if(response.message_id == 0) {
                        this.$emit("cancel");
                        this.$emit("fetchData");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            this.loading = true;
            await this.getLoanDisbursement({
                search: this.edit_data.loan_number
                    ? this.edit_data.loan_number
                    : null
            }).finally(() => {
                this.loading = false;
            });
            if (!isEmpty(this.edit_data)) {
                this.model.loan_id = this.edit_data.loan_id;
                this.model.recovery_date = this.edit_data.recovery_date;
                this.model.amount = this.edit_data.amount;
                this.model.remarks = this.edit_data.remarks;
            }
        },
        clearInput() {
            this.model.loan_id = null;
            this.model.amount = null;
            this.model.recovery_date = moment().format("DD-MM-YYYY");
            this.model.remarks = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LAON RECOVERY",
                desc: not.text
            });
        }
    }
};
</script>
